<template>
  <section class="m-core-wrapper">
    <div class="m-core-title">核心实力</div>
    <div class="m-core-contain">
      <div
        v-for="item in coreList"
        :key="item.title"
        v-scroll-lazy="{ mode: 'bg', src: item.image }"
        class="m-core-item"
      >
        <div class="m-title">{{ item.title }}</div>
        <div class="m-desc">{{ item.desc }}</div>
        <div v-if="item.extra" class="m-extra">{{ item.extra }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { coreList } from '../constants/index'

export default defineComponent({
  name: 'MobileCore',
  setup() {
    return {
      coreList
    }
  }
})
</script>

<style lang="less" scoped>
.m-core-title {
  font-size: 0.56rem;
  font-weight: bold;
  text-align: center;
}
.m-core-contain {
  width: 6.78rem;
  margin: 0.6rem 0.36rem 1rem 0.36rem;
  .m-core-item {
    width: 100%;
    height: 4.2rem;
    padding: 0.6rem 0.4rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 0.5rem;
    border-radius: 0.15rem;
    position: relative;
  }
  .m-title {
    font-size: 0.36rem;
    font-weight: bold;
    color: #fff;
  }
  .m-desc {
    font-size: 0.28rem;
    color: #fff;
    line-height: 0.38rem;
    margin-top: 0.4rem;
  }
  .m-extra {
    font-size: 0.2rem;
    color: #fff;
    opacity: 0.7;
    position: absolute;
    line-height: 0.3rem;
    bottom: 0.4rem;
    margin-right: 0.4rem;
  }
}
</style>
